import { Injectable } from '@angular/core';
import { VwUsuarioGrupoModel } from 'src/models/vw-usuario-grupo.model';
import { PublicacaoModel } from 'src/models/publicacao.model';

@Injectable()
export class LocalStorageService {

    constructor() {}

    logOut(): void {
        localStorage.clear();
        sessionStorage.clear();
    }
    setUsuarioLogado(tipo: VwUsuarioGrupoModel): void {
        window.localStorage.setItem('usuarioLogado', JSON.stringify(tipo));
    }
    getUsuarioLogado(): VwUsuarioGrupoModel {
       return (JSON.parse(window.localStorage.getItem('usuarioLogado')) as VwUsuarioGrupoModel);
    }
    setToken(token: string): void {
        window.localStorage.setItem('token', token);
    }
    getToken(): string {
        return window.localStorage.getItem('token');
    }
    setPublicacaoSelecionada(publicacao: PublicacaoModel): void {
      window.localStorage.setItem('publicacaoSelecionada', JSON.stringify(publicacao));
    }
    getPublicacaoSelecionada(): PublicacaoModel {
     return (JSON.parse(window.localStorage.getItem('publicacaoSelecionada')) as PublicacaoModel);
    }
}

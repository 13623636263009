import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocalStorageService } from 'src/shared/localStorage.service';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SpinnerService } from 'src/shared/spinner.service';
import { UtilService } from 'src/shared/util.service';
import { SurveyService } from 'src/shared/survey.service';
import { UsuarioLogadoGuard } from 'src/guards/usuario-logado.guard';
import { LoginsProvider } from 'src/providers/logins.provider';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    HttpClientModule
  ],
  providers: [LocalStorageService, SpinnerService, UtilService, SurveyService, 
    LoginsProvider, HttpClient, 
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable()
export class SurveyService {
    @Output() eventLimparModelo: EventEmitter<any> = new EventEmitter();
    @Output() eventDescartarAlteracaoModeloPesquisa: EventEmitter<any> = new EventEmitter();
    @Output() eventLimparModeloPesquisa: EventEmitter<any> = new EventEmitter();
    
    constructor() {}

    setResetJson() {
        this.eventLimparModelo.emit()
    }

    getResetJson() {
        return this.eventLimparModelo;
    }

    setDescartarAlteracaoModeloPesquisa() {
        this.eventDescartarAlteracaoModeloPesquisa.emit()
    }

    getDescartarAlteracaoModeloPesquisa() {
        return this.eventDescartarAlteracaoModeloPesquisa;
    }

    setLimparModeloPesquisa() {
        this.eventLimparModeloPesquisa.emit()
    }

    getLimparModeloPesquisa() {
        return this.eventLimparModeloPesquisa;
    }
}

import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable()
export class SpinnerService {

    constructor(private spinner: NgxSpinnerService) {}
    showLoading(): void {
      this.spinner.show();
    }
    showLoadingWithTimeout(seconds: number): void {
      this.showLoading();
      setTimeout(() => {
        /** spinner ends after x seconds */
        this.spinner.hide();
      }, seconds * 1000);
    }

    hideLoading(): void {
      this.spinner.hide();
    }
}

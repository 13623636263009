import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/shared/util.service';
import { LocalStorageService } from 'src/shared/localStorage.service';
import { VwUsuarioGrupoModel } from 'src/models/vw-usuario-grupo.model';
import * as $ from 'jquery';
import { SurveyService } from 'src/shared/survey.service';

export interface NavBarUrl {
  nome: string;
  url: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'PortalPesquisa';

  usuario: VwUsuarioGrupoModel;
  ehMobile = false;
  usuarioLogado = false;
  pageLogin = false;
  pageGerenciarLogins = false;

  listaItensMenu: any[] = [];

  constructor(public surveyService: SurveyService, public route: Router, private util: UtilService, private storageService: LocalStorageService) {
    this.ehMobile = util.VerifyMobileBrowser();
    this.AtualizarNavBar();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterContentChecked() {

    $('.menu-pagina a').removeClass('opacity-50');

    if (this.route.url.indexOf(';') === -1) {
      if (this.route.url.replace('/', '') !== '') {
        $('#' + this.route.url.split('/')[1].replace('/', '')).addClass('opacity-50');
        $('#home').removeClass('opacity-50');

      } else {
        $('#home').addClass('opacity-50');
      }
    } else {
      const idElemento = this.route.url.split('/')[1].substring(0, this.route.url.indexOf(';') - 1);
      const elemento = this.listaItensMenu.find( c => c.id === idElemento);
      if (elemento != null) {
        $('#' + idElemento).addClass('opacity-50');
        $('#home').removeClass('opacity-50');
      }

    }

    if (this.storageService.getToken() != null) {
      this.usuarioLogado = true;
    }
    if (this.route.url === '/login') {
      this.pageLogin = true;
      this.pageGerenciarLogins = false;
    } else if (this.route.url === '/gerenciar-logins') {
      this.pageLogin = false;
      this.pageGerenciarLogins = true;
    } else {
      this.pageLogin = false;
      this.pageGerenciarLogins = false;
    }
  }
  AtualizarNavBar() {
    this.listaItensMenu = [];
    if (this.storageService.getToken() != null) {
      this.usuarioLogado = true;
      this.usuario = this.storageService.getUsuarioLogado();
      this.usuario.NomeAbreviado = this.usuario.Name.split(' ')[0];
    }
    if (this.usuario && this.usuario.Role === 'Administrador') {
      this.listaItensMenu.push({ nome: 'Início', url: '/', id: 'home' });
      this.listaItensMenu.push({ nome: 'criar modelo de pesquisa', url: '/pesquisa', id: 'pesquisa' });
      this.listaItensMenu.push({ nome: 'Publicar', url: '/publicacao/criar/0', id: 'publicacao'});
      this.listaItensMenu.push({ nome: 'Resultados', url: '/resultados', id: 'resultados'});
      this.listaItensMenu.push({ nome: 'Gerenciar logins', url: '/gerenciar-logins', id: 'gerenciar-logins'});

    } else if (this.usuario && this.usuario.Role === 'AdministradorTecnico') {
      this.listaItensMenu.push({ nome: 'Início', url: '/', id: 'home'});
      this.listaItensMenu.push({ nome: 'criar modelo de pesquisa', url: '/pesquisa', id: 'pesquisa' });
      this.listaItensMenu.push({ nome: 'Publicar', url: '/publicacao/criar/0', id: 'publicacao'});
      this.listaItensMenu.push({ nome: 'Resultados', url: '/resultados', id: 'resultados' });
      this.listaItensMenu.push({ nome: 'Público Alvo', url: '/publico-alvo', id: 'publico-alvo' });
      this.listaItensMenu.push({ nome: 'Gerenciar logins', url: '/gerenciar-logins', id: 'gerenciar-logins'});

    } else if (this.usuario && this.usuario.Role === 'Criador') {
      this.listaItensMenu.push({ nome: 'Início', url: '/', id: 'home' });
      this.listaItensMenu.push({ nome: 'criar modelo de pesquisa', url: '/pesquisa', id: 'pesquisa' });

    }else if (this.usuario && this.usuario.Role === 'Cliente') {
      this.listaItensMenu.push({ nome: 'Início', url: '/', id: 'cliente' });
    }
  }
  sair() {
    this.storageService.logOut();
    this.usuarioLogado = false;
    this.route.navigate(['/login']);
  }

  limparJsonModeloPesquisa(id){
    var rota = this.listaItensMenu.find(c => c.id == id).url;

    window.localStorage.setItem('rotaAtual', rota);

    if(id == "pesquisa"){
      if(this.route.url == "/pesquisa"){
        location.reload(true);
      }
    }
  }

  onActivate(event) {
    document.body.scrollTop = 0;
  }
}


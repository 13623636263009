import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgModule, Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRegistrationModel } from 'src/models/user-registration.model';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/shared/localStorage.service';
import { CargoUsuarioModel } from 'src/models/grupo-usuario-aplicacao.model';
import { VwUsuarioGrupoModel } from 'src/models/vw-usuario-grupo.model';
import { ApiResponseModel } from 'src/models/api-response.model';
import { Token } from 'src/models/token.model';


@Injectable()
export class LoginsProvider {

    constructor(private readonly http: HttpClient, private localStorage: LocalStorageService) {
    }

    headers: HttpHeaders = new HttpHeaders();
    private urlLogar = `${environment.apiUrlAuth}/oauth/token`;

    private routePrefix = `${environment.apiUrl}/Account/`;
    private urlVerificarToken = `${this.routePrefix}/VerificarToken`;
    private urlCriarUsuario = `${this.routePrefix}/CadastrarUsuario`;
    private urlListarCargosUsuario = `${this.routePrefix}/ListarUsuarioPorCargo`;
    private urlAtivarUsuario = `${this.routePrefix}/AtivarUsuario?idUsuario=`;
    private urlDesativarUsuario = `${this.routePrefix}/DesativarUsuario?idUsuario=`;
    private urlListarUsuario = `${this.routePrefix}/ListarUsuario`;
    private urlGetUsuario = `${this.routePrefix}/GetUsuario`;

    VerificarToken(): Observable<any> {
        return this.http.get<ApiResponseModel>(this.urlVerificarToken, this.getHeaders())
        .pipe(
            map(response => {
                return response;
            },
                error => this.handleError(error))
        );
    }

    Logar(usuario: UserRegistrationModel): Observable<any> {
        const data = 'grant_type=password&username=' + usuario.UserName + '&password=' + usuario.Senha + '&client_id=PortalPesquisa';
        return this.http.post(this.urlLogar, data)
        .pipe(
            map(response => {
                return response;
            },
                error => this.handleError(error))
        );
    }

    ListarUsuario(): Observable<VwUsuarioGrupoModel[]> {
        return this.http.get<ApiResponseModel>(this.urlListarUsuario, this.getHeaders())
        .pipe(
            map(response => {
                return response.Data as VwUsuarioGrupoModel[];
            },
                error => this.handleError(error))
        );
    }

    GetUsuario(): Observable<VwUsuarioGrupoModel> {
      return this.http.get<ApiResponseModel>(this.urlGetUsuario, this.getHeaders())
      .pipe(
          map(response => {
              return response.Data as VwUsuarioGrupoModel;
          },
              error => this.handleError(error))
      );
  }

    ListarUsuariosPorCargo(): Observable<CargoUsuarioModel> {
        return this.http.get<ApiResponseModel>(this.urlListarCargosUsuario, this.getHeaders())
        .pipe(
            map(response => {
                return response.Data as CargoUsuarioModel;
            },
                error => this.handleError(error))
          );
    }

    CadastrarUsuario(user: UserRegistrationModel): Observable<any> {
      return this.http.post<UserRegistrationModel>(this.urlCriarUsuario, user, this.getHeaders())
      .pipe(
          map(response => {
              return response;
          },
              error => this.handleError(error))
      );
    }
    AtivarUsuario(idUsuario: string): Observable<any> {
      return this.http.get<ApiResponseModel>(`${this.urlAtivarUsuario}${idUsuario}`, this.getHeaders())
      .pipe(
          map(response => {
              return response;
          },
              error => this.handleError(error))
      );
    }
    DesativarUsuario(idUsuario: string): Observable<any> {
      return this.http.get<ApiResponseModel>(`${this.urlDesativarUsuario}${idUsuario}`, this.getHeaders())
      .pipe(
          map(response => {
              return response;
          },
              error => this.handleError(error))
      );
    }

    private getHeaders() {
        const ACCESS_TOKEN = this.localStorage.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Bearer ' + ACCESS_TOKEN
            })
        };
        return httpOptions;
    }

    private handleError(error: any): Observable<any> {

        if (error.status === 0) {
            return of('Erro de conexão.');
        }

        let errMsg: string;
        if (error instanceof HttpErrorResponse) {
            const err = error.message || JSON.stringify(error.error);
            errMsg = `${error.status} - ${error.statusText || ''} Details: ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.log(JSON.stringify(errMsg));
        return of(error);
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './home/home.module#HomeModule'},
  { path: 'home', loadChildren: './home/home.module#HomeModule'},
  { path: 'cliente', loadChildren: './cliente/cliente.module#ClienteModule'},
  { path: 'login', loadChildren: './login/login.module#LoginModule'},
  { path: 'pesquisa', loadChildren: './pesquisa/pesquisa.module#PesquisaModule'},
  { path: 'pesquisa-cliente', loadChildren: './pesquisa/pesquisa-cliente/pesquisa-cliente.module#PesquisaClienteModule'},
  { path: 'editar-pesquisa', loadChildren: './pesquisa/editar-pesquisa/editar-pesquisa.module#EditarPesquisaModule'},
  { path: 'resposta-pesquisa', loadChildren: './pesquisa/resposta-pesquisa/resposta-pesquisa.module#RespostaPesquisaModule'},
  { path: 'resultado-publicacao', loadChildren: './resultados/resultado-publicacao/resultado-publicacao.module#ResultadoPublicacaoModule'},
  { path: 'publicacao/editar/:idPublicacao', loadChildren: './publicacao/editar/editar-publicacao.module#EditarPublicacaoModule'},
  { path: 'publicacao/criar/:idModeloPesquisa', loadChildren: './publicacao/publicar/publicacao.module#PublicacaoModule'},
  { path: 'resultados', loadChildren: './resultados/resultados.module#ResultadosModule'},
  { path: 'gerenciar-logins', loadChildren: './gerenciar-logins/gerenciar-logins.module#GerenciarLoginsModule'},
  { path: 'publico-alvo', loadChildren: './publico-alvo/publico-alvo.module#PublicoAlvoModule'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
